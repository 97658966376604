@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,700;1,800&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300&family=Public+Sans:wght@300;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Montserrat";
  scroll-behavior: smooth !important;
}

ul,
li {
  list-style-type: none;
}

.head_bg {
  background-image: url("/public/asset/images/header.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.card {
  background: #122069;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.16);
  border-radius: 32px 0px;
}

ul {
  list-style: circle;
}
